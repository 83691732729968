import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { ROLE_LEVEL } from '@shared/service-proxies/service-proxies';
import { SessionStorageKey } from '@app/shared/common/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";

    imgToChucDaoTao = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/house.svg';
    imgThuVienNoiDung = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/open-book.svg';
    imgBaoCao = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/report.svg';
    imgDanhMuc = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/computer.svg'
    imgHeThong = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/setting.svg';
    imgQuanTri = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/account.svg';
    imgChuongTrinhDaoTao = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/graduating-student.png';
    imgUngDung = 'http://daotao.ytehocduong.vn/assets/common/images/image-icon/soup.svg';

    mnToChucDaoTao = [
        new AppMenuItem('Pages_ToChucDaoTao_KhoaHoc', 'Pages.ToChucDaoTao.KhoaHoc', 'fa-laptop-code', '/app/training-management/course', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien]),
        new AppMenuItem('Pages_ToChucDaoTao_KyThi', 'Pages.ToChucDaoTao.KyThi', 'fa-location-arrow', '/app/training-management/exam', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien]),
        //new AppMenuItem('Pages_ToChucDaoTao_LoTrinhDaoTao', 'Pages.ToChucDaoTao.LoTrinhDaoTao', 'fa-chart-line', '/app/training-management/list-roadmap', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien]),
        new AppMenuItem('Pages_ToChucDaoTao_LichDaoTao', 'Pages.ToChucDaoTao.LichDaoTao', 'far fa-calendar-alt', '/app/training-management/schedule', [ROLE_LEVEL.QuanTriVien]),
        //new AppMenuItem('Pages_ToChucDaoTao_KhaoSat', 'Pages.ToChucDaoTao.KhaoSat', 'fa-lightbulb', '/app/training-management/survey', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien]),
    ];

    mnChuongTrinhDaoTao = [
        new AppMenuItem('Pages_DaoTaoCuaToi_NoiDungDangHoc', 'Pages.DaoTaoCuaToi.NoiDungDangHoc', 'fa-list-alt', '/app/my-training/my-lesson-doing', [ROLE_LEVEL.HocVien]),
        new AppMenuItem('Pages_DaoTaoCuaToi_KhoaHocCuaToi', 'Pages.DaoTaoCuaToi.KhoaHocCuaToi', 'fa-layer-group', '/app/my-training/my-course', [ROLE_LEVEL.HocVien]),
        new AppMenuItem('Pages_DaoTaoCuaToi_KyThiCuaToi', 'Pages.DaoTaoCuaToi.KyThiCuaToi', 'fa-glasses', '/app/my-training/my-exam', [ROLE_LEVEL.HocVien]),
        //new AppMenuItem('Pages_DaoTaoCuaToi_LoTrinhHocTap', 'Pages.DaoTaoCuaToi.LoTrinhHocTap', 'fa-seedling', '/app/my-training/my-roadmap', [ROLE_LEVEL.HocVien]),
        //new AppMenuItem('Pages_DaoTaoCuaToi_KhaoSatCuaToi', 'Pages.DaoTaoCuaToi.KhaoSatCuaToi', 'fa-person-breastfeeding', '/app/my-training/my-survey', [ROLE_LEVEL.HocVien]),
    ];

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService
    ) {
    }

    getMenu(): AppMenu {

        return new AppMenu('Pages_Dashboard', 'Pages_Dashboard', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'fa-tachometer-alt', '/app/admin/hostDashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'fa-building', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'fa-cube', '/app/admin/editions'),
            new AppMenuItem('Cổng thông tin', 'Pages.Portal', 'fa-newspaper', '/app/portal/dashboard', null, []),

            // Tổ chức đào tạo
            ... this.mnToChucDaoTao,

            //Thư viện nội dung
            new AppMenuItem('Pages_ThuVienNoiDung', 'Pages.ThuVienNoiDung', 'book', '', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien], [
                new AppMenuItem('Pages_ThuVienNoiDung_NganHangNoiDung', 'Pages.ThuVienNoiDung.NganHangNoiDung', 'fa-folder', '/app/content-library/content-repository'),
                new AppMenuItem('Pages_ThuVienNoiDung_NganHangCauHoi', 'Pages.ThuVienNoiDung.NganHangCauHoi', 'fa-question-circle', '/app/content-library/question-bank'),
                //new AppMenuItem('Pages_ThuVienNoiDung_NganHangCauKhaoSat', 'Pages.ThuVienNoiDung.NganHangCauKhaoSat', 'fa-ethernet', '/app/content-library/question-survey'),
                new AppMenuItem('Pages_ThuVienNoiDung_NganHangDeThi', 'Pages.ThuVienNoiDung.NganHangDeThi', 'fa-bank', '/app/content-library/exam-bank'),
            ],
                //image menu
                this.imgThuVienNoiDung),

            //Chương trình đào tạo của tôi
            ... this.mnChuongTrinhDaoTao,

            //Ứng dụng MyApp
            new AppMenuItem('Pages_MyApp', 'Pages.MyApp', 'windows', '', [ROLE_LEVEL.HocVien], [
                new AppMenuItem('Pages_MyApp_Calendar', 'Pages.MyApp.Calendar', 'fa-calendar', '/app/my-application/calendar'),
                new AppMenuItem('Pages_MyApp_MyLibrary', 'Pages.MyApp.MyLibrary', 'fa-check', '/app/my-application/my-library'),
            ],
                //image menu
                this.imgUngDung),
            //Báo cáo
            new AppMenuItem('Pages_BaoCao', 'Pages.BaoCao', 'area-chart', '', [ROLE_LEVEL.QuanTriVien, ROLE_LEVEL.GiangVien], [
                new AppMenuItem('Pages_BaoCao_KhoaHoc', 'Pages.BaoCao.KhoaHoc', 'fa-laptop-code', '/app/report/course'),
                new AppMenuItem('Pages_BaoCao_KyThi', 'Pages.BaoCao.KyThi', 'fa-location-arrow', '/app/report/exam'),
                //new AppMenuItem('Pages_BaoCao_KhaoSat', 'Pages.BaoCao.KhaoSat', 'fa-person-breastfeeding', '/app/report/survey'),
                new AppMenuItem('Pages_BaoCao_DiemDanh', 'Pages.BaoCao.DiemDanh', 'fa-calendar-check', '/app/he-thong/attendance-management'),
                new AppMenuItem('Pages_BaoCao_BaiThi', 'Pages.BaoCao.BaiThi', 'far fa-calendar-alt', '/app/report/exam-test'),
                new AppMenuItem('Pages_HeThong_ChungNhan', 'Pages.HeThong.ChungNhan', 'fa-certificate', '/app/he-thong/certificate-management'),
            ],
                //image menu
                this.imgBaoCao),
            //Danh mục
            new AppMenuItem('Pages_DanhMuc', 'Pages.DanhMuc', 'database', '', [ROLE_LEVEL.QuanTriVien], [
                new AppMenuItem('Pages_DanhMuc_ChuDe', 'Pages.DanhMuc.ChuDe', 'fa-book', '/app/danh-muc/topic'),
                //new AppMenuItem('Pages_DanhMuc_ChiPhi', 'Pages.DanhMuc.ChiPhi', 'fa-dollar-sign', '/app/danh-muc/cost'),
                new AppMenuItem('Pages_DanhMuc_ViTriChucDanh', 'Pages.DanhMuc.ViTriChucDanh', 'fa-arrows-alt', '/app/danh-muc/position'),
                new AppMenuItem('Pages_Ability', 'Pages.Ability', 'fa-signal', '/app/danh-muc/proficiency'),
            ],
                //image menu
                this.imgDanhMuc),
            //Hệ thống
            new AppMenuItem('Pages_HeThong', 'Pages.HeThong', 'setting', '', [ROLE_LEVEL.QuanTriVien], [
                new AppMenuItem('Pages_HeThong_TaiKhoanDangKy', 'Pages.HeThong.TaiKhoanDangKy', 'fa-user-plus', '/app/he-thong/user-register'),
                new AppMenuItem('Pages_HeThong_TaiKhoan', 'Pages.HeThong.TaiKhoan', 'fa-user-tie', '/app/he-thong/user'),
                new AppMenuItem('Pages_HeThong_NhomNguoiDung', 'Pages.HeThong.NhomNguoiDung', 'fa-users', '/app/he-thong/user-group'),
                new AppMenuItem('Pages_HeThong_CoCauToChuc', 'Pages.HeThong.CoCauToChuc', 'fa-campground', '/app/danh-muc/department'),
                new AppMenuItem('Pages_HeThong_CaiDat', 'Pages.HeThong.CaiDat', 'fa-wrench', '/app/he-thong/system-management/setting'),
            ],
                //image menu
                this.imgHeThong),

            //Cơ sở đăng ký
            new AppMenuItem('Pages_CoSoDangKy', 'Pages.CoSoDangKy', 'area-chart', '', null, [
                new AppMenuItem('Pages_CoSoDangKy_TenantRegister', 'Pages.CoSoDangKy.TenantRegister', 'fa-check', '/app/thue-bao/tenant-register'),
            ]),

            new AppMenuItem('Pages_HeThongHost', 'Pages.HeThongHost', 'setting', '', null, [
                new AppMenuItem('Pages_HeThong_CayDanhMuc', 'Pages.HeThong.CayDanhMuc', 'fa-check', '/app/danh-muc-chung/cay-danh-muc'),
                new AppMenuItem('Pages_HeThong_DonViHanhChinh', 'Pages.HeThong.DonViHanhChinh', 'fa-check', '/app/danh-muc-chung/don-vi-hanh-chinh'),
                new AppMenuItem('Pages_HeThong_ThietLapChung', 'Pages.HeThong.ThietLapChung', 'fa-wrench', '/app/thue-bao/thiet-lap-chung'),
                new AppMenuItem('Pages_HeThong_ThietLapThongBaoChung', 'Pages.HeThong.ThietLapThongBaoChung', 'fa-wrench', '/app/he-thong/thiet-lap-thong-bao-chung'),
            ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [ROLE_LEVEL.QuanTriVien], [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'fa-check', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'fa-check', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'fa-check', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'fa-check', '/app/admin/languages'),
                new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'fa-check', '/app/admin/languagetexts'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'fa-check', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'fa-check', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'fa-check', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'fa-check', '/app/admin/tenantSettings'),
                //APP
                new AppMenuItem('Pages_HeThong_QuanLyThongBao', 'Pages.HeThong.QuanLyThongBao', 'fas fa-bullhorn', '/app/he-thong/notification-management'),
                new AppMenuItem('Pages_HeThong_QuanLyFileMinio', 'Pages.HeThong.QuanLyFileMinio', 'fa-folder', '/app/he-thong/file-minio-management'),
            ],
                //image menu
                this.imgQuanTri),
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        //Custom
        if (menuItem.roleLevel) {

            let keyCurrentRoleLevel = SessionStorageKey.keyCurrentRoleLevel + "-" + this._appSessionService.userId;
            let _currentRoleLevel = sessionStorage.getItem(keyCurrentRoleLevel);

            if (_currentRoleLevel) {

                let objFind = menuItem.roleLevel.find(item => {
                    return item.toString() == _currentRoleLevel;
                });

                if (!objFind) {
                    return false;
                }
            }
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
